@import '../../styles/_variables.scss';

.regras-container {
    position: relative;
    background: url('../../assets/login/bg.png') center/cover no-repeat;
    color: $text-color;
    width: 100%;
    height: 110vh;
    padding: 20px;
    padding-top: 0px;

    &::before {
        content: '';
        position: absolute;
        inset: 0;
        background: rgba(0, 0, 0, 0.822);
        z-index: 0;
    }

    .container {
        position: relative;
        max-width: 1200px;
        margin: 0 auto;
        padding: 20px 0;
        z-index: 1;

        h2 {
            text-align: center;
            font-size: 1.5rem; // Tamanho da fonte para desktop
            font-weight: bold;
            color: $text-color;
            letter-spacing: 1px;
            margin-bottom: 20px;

            @media (max-width: 768px) {
                font-size: 1.2rem; // Tamanho da fonte para mobile
            }
        }
    }

    .step-dot {
        transition: background-color 0.3s ease;
    }

    .step-card {
        background-color: $card-bg;
        border-radius: 20px;
        padding: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        z-index: 1;
        margin-bottom: 0px;
        text-align: center;
        .step-content {
            text-align: center;

            h3 {
                font-size: 1.3rem; // Tamanho da fonte para desktop
                color: $text-color;
                margin-bottom: 10px;

                @media (max-width: 768px) {
                    font-size: 1rem; // Tamanho da fonte para mobile
                }
            }

            p {
                font-size: 1rem; // Tamanho da fonte para desktop
                color: $text-color;

                @media (max-width: 768px) {
                    font-size: 0.9rem; // Tamanho da fonte para mobile
                }
            }
        }

        .step-actions {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
}

// Animação personalizada para as imagens
@keyframes bounce-scale-rotate {

    0%,
    100% {
        transform: scale(1) rotateY(0deg);
    }

    50% {
        transform: scale(1.1) rotateY(15deg);
    }
}

.img-3d {
    width: 300px;
    animation: bounce-scale-rotate 2s ease-in-out infinite;
    padding: 20px;

    @media (max-width: 768px) {
        width: 300px; // Tamanho da imagem ajustado para mobile
    }
}
.square-card {
    position: relative;
    background-color: rgba(0, 0, 0, 0.85); // Fundo preto com opacidade ajustada
    border-radius: 8px;
    padding: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden; // Garante que o conteúdo não ultrapasse as bordas
    min-width: 390px;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('/assets/login/square.png'); // Imagem de fundo
        background-size: cover;
        background-position: center;
        opacity: 0.2; // Reduz a opacidade da imagem de fundo
        z-index: 1;
    }

    // Garante que o conteúdo do card fique acima da imagem de fundo
    > * {
        position: relative;
        z-index: 2;
    }
}

/* Keyframes para animar a opacidade */
@keyframes fadeOpacity {
    0% {
        opacity: 0.2; // Início com opacidade reduzida
    }
    100% {
        opacity: 0.6; // Anima até uma opacidade maior
    }
}

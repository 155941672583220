
@import '../../styles/_variables';

.login-page {
  background-image: url('/assets/login/bg.png');
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: aqua;
  }
}

.login-container {
  border-radius: 8px;
  width: 100%;
  max-width: 400px;

}

.login-input {
  border-color: $primary-color;
}

.login-button {
    width: 100%;
    cursor: pointer;
    transition: ease 300ms;
}

.login-footer {
    color: #fff;
}
.login-footer span {
  color: $text-blue;
  cursor: pointer;
}

.logo {
    width: 150px;
}

.form-container {
    width: 100%;
}


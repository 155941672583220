.custom-button {
    background: linear-gradient(180deg, #69fdf8, #55dcdc, #248794, #146778, #42aeb2 100%);
    border-radius: 30px;
    color: white;
    padding: 0.75rem 2rem;
    font-size: 1.25rem;
    font-weight: bold;
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    border: 2px solid #ffd700;
    border-bottom: 4px solid #926110;
    background-clip: padding-box;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  
    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }
  
    &:hover:disabled {
      transform: none;
      box-shadow: none;
    }
  
    &:hover {
      transform: scale(1.05);
      box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.4);
    }
  
    &:active {
      transform: scale(0.95);
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    }
  
    &::before {
      content: '';
      position: absolute;
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
      border-radius: inherit;
      border: 2px solid rgba(255, 215, 0, 0.6);
      z-index: -1;
    }
  
    .spinner {
      border: 4px solid rgba(255, 255, 255, 0.3); /* Light grey */
      border-top: 4px solid white; /* White */
      border-radius: 50%;
      width: 24px;
      height: 24px;
      animation: spin 0.8s linear infinite;
    }
  
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  
.background-container {
  position: relative;
  background-image: url('../../assets/login/bg.png');
  background-size: cover;
  background-position: center;
  padding: 0px 0px;
  /* Aumentando o padding nas laterais */
  color: #fff;

  /* Preenchendo 100% da largura e altura da tela */
  width: 100%;
  height: 100%;
  padding-bottom: 100px;
  /* Configurações de layout flexível */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* Centralizando o conteúdo horizontalmente e verticalmente */

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.822);
    /* Overlay escuro */
    z-index: 0;
  }

  .content {
    position: relative;
    z-index: 10;
    /* Coloca o conteúdo acima do overlay */
    display: flex;
    flex-direction: column;
    /* No mobile, os itens ficam um abaixo do outro */
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    /* Adiciona espaçamento entre os itens */
    width: 100%;
    max-width: 1200px;
    /* Controla a largura máxima do conteúdo para telas grandes */
    padding: 20px;
    /* Adiciona um espaçamento interno */

    @media (min-width: 992px) {
      flex-direction: row;
      /* Em telas maiores, os itens ficam lado a lado */
      justify-content: space-between;
      /* Adiciona espaçamento entre os itens */
      align-items: flex-start;
      /* Alinha os itens no topo em telas grandes */
      gap: 32px;
      /* Mais espaço entre os elementos na versão web */
    }
  }

  /* Ajustes para o WelcomeBox e MusicList */
  .welcome-box {
    flex: 5;
    /* Ocupa 5 partes em telas grandes */
    width: 100%;
    /* Ocupa 100% da largura disponível no mobile */
    max-width: 100%;
    /* Evita que ultrapasse o tamanho */
  }

  .music-list {
    flex: 7;
    /* Ocupa 7 partes em telas grandes */
    width: 100%;
    /* Ocupa 100% da largura disponível no mobile */
    max-width: 100%;
    /* Evita que ultrapasse o tamanho */
  }
}

.WelcomeBox {
  width: 100%;
  margin-bottom: 24px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  /* Melhora o sombreamento */
}

.MusicList {
  width: 100%;
  margin-bottom: 24px;

}

.list-musics {
  width: 100%;
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #ffaa00 #17161A;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #17161A;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ffaa00;
    border-radius: 50%;
    border: 3px solid #17161A;
    box-shadow: inset 1px 1px 5px rgba(255, 255, 255, 0.6),
      1px 1px 8px rgba(0, 0, 0, 0.5);
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #ffaa00;
    box-shadow: inset 1px 1px 5px rgba(255, 255, 255, 0.8),
      1px 1px 10px rgba(0, 0, 0, 0.7);
  }


  /* Estilos para os cards */
  .card-music {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }

  .card-content {
    background-color: #252429;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
  }

  .card-title {
    font-weight: bold;
    font-size: 14px;
    color: #FFFFFF;
  }

  .card-subtitle {
    color: #A0AEC0;
    font-size: 13px;
  }

  .card-button {
    margin-top: 16px;
    color: #fff;
    font-size: 18px;
    padding-left: 0;
    &:hover {
      background-color: #ffffff7e;
    }
  }
}
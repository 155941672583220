@import '../../styles/_variables';

.banner-profile {
  height: 200px;
  width: 100%;
  position: relative;
  background-color: #000000db;
  display: flex;
  align-items: center;
  justify-content: center;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.avatar-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 100px;
  border-radius: 50%;
  background-color: #0e0e10;
  padding: 6px;
  border: 3px solid #000;
  z-index: 2;
}

.page-container {

  position: relative;
  background-color: #0e0e10; // Fundo de imagem igual ao PlayPage
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  padding: 28px 20px;
  color: #fff;
  

  .content {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;
    width: 100%;
    background-color: #0e0e10;
  }
}

.content {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
}

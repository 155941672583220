.background-container {
    position: relative;
    background: url('../../assets/login/bg.png') center/cover no-repeat; 
    background-size: cover;
    background-position: center;
    color: #fff;
    width: 100%;
    height: 100%;
    padding-bottom: 100px;
  
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.7);
      z-index: 0;
    }
  }
  
  .terms-container {
    z-index: 10;
    position: relative;
    padding: 40px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #17161a; 
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  
    ul {
      list-style-type: decimal;
      padding-left: 20px;
  
      li {
        margin-bottom: 15px;
        font-size: 16px;
        line-height: 1.5;
      }
    }
  }
  
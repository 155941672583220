.demo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    color: #fff;
    position: relative;
    background: url('../../assets/login/bg.png') center/cover no-repeat;
    height: 100%;
  
    &::before {
      content: '';
      position: absolute;
      inset: 0;
      background: rgba(0, 0, 0, 0.7); // Transparência no background
      z-index: 0; // O background fica em um nível mais baixo
      
    }
  
    .text-container,
    .iframe-container {
      position: relative;
      z-index: 1; // Eleva o conteúdo acima do pseudo-elemento ::before
    }
  
    .text-container {
      text-align: center;
      margin-bottom: 8px;
      background-color: #17161A;
      padding: 20px;
      border-radius: 10px;
      animation: fadeIn 1s ease-in-out;
      width: 100%;
      h1 {
        font-size: 1.8rem;
        margin-bottom: 15px;
        font-weight: 600;
        color: #ffd700;
      }
  
      p {
        font-size: 1rem;
        color: #fff;
      }
    }
  
    .guitar-icon {
      font-size: 3rem;
      color: #ffa500;
      margin-bottom: 15px;
      animation: swing 2s infinite ease-in-out;
    }
  
    .loading-text {
      font-size: 1.2rem;
      color: #fff;
      animation: pulse 1s infinite;
      background-color: #17161A;
      padding: 20px;
      width: 100%;
      border-radius: 10px;
    }
  
    .iframe-container {
      width: 100%;
      height: 50vh; // O iframe ocupará 50% da altura da viewport
      overflow: hidden;
      border-radius: 10px;  
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 40px;
    }
  
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(-20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  
    @keyframes swing {
      0% { transform: rotate(0deg); }
      50% { transform: rotate(10deg); }
      100% { transform: rotate(0deg); }
    }
  
    @keyframes pulse {
      0% { opacity: 0.7; }
      50% { opacity: 1; }
      100% { opacity: 0.7; }
    }
  }
  
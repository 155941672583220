// src/styles/_variables.scss
$primary-color: #ff7e5f;
$secondary-color: #feb47b;
$background-color: #1c1c1c;
$bg-card: #1F2937;
$text-color: #ffffff;
$card-bg: #17161A;
$radius: 6px;
$primary-color: #ffaa00;
$secondary-color: #10b981;
$text-color: #fff;
$bg-overlay: rgba(0, 0, 0, 0.822);
$shadow-color: rgba(0, 0, 0, 0.3);
$balance-bg: #252429;
$button-gradient: linear-gradient(90deg, rgba(255,126,95,1) 0%, rgba(254,180,123,1) 100%);

$text-blue: #69fdf8;


.mr-20px {
    margin-right: 20px;
}

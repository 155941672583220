@import '../../styles/variables';

.balanceCard {
  background-color: #17161A;  // Fundo preto do card principal
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 100%;
  position: relative;

  .bgGray {
    background-color: $balance-bg; // Fundo cinza
    border-radius: 6px;
    padding: 20px;
    position: relative; // Necessário para o pseudo-elemento da imagem
    overflow: hidden;  // Para garantir que a imagem não vaze para fora

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 150px;  // Ajuste o tamanho da imagem conforme necessário
      height: 150px;
      background-image: url('../../assets/money-bag.png');  // Imagem do saco de dinheiro
      background-position: right center;
      background-repeat: no-repeat;
      background-size: contain;
      opacity: 0.8;  // Deixa a imagem mais suave sobre o fundo
      z-index: 1;
    }
  }
  
  .title {
    font-size: 1.25rem;
    margin-bottom: 8px;
    color: #ffffff;
    z-index: 2;
    position: relative;
  }

  .amount {
    font-size: 2rem;
    font-weight: bold;
    color: #10B981;
    z-index: 2;
    position: relative;
  }

  .buttonGroup {
    display: flex;
    margin-top: 20px;
    z-index: 2;
    position: relative;
 

    button {
      margin-right: 8px;
    }
  }
}

// ViolationPage.scss
.violation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #0a0a0a; // Fundo escuro estilo hacker
    color: #00ff00; // Cor estilo terminal hacker
    font-family: 'Courier New', Courier, monospace; // Fonte estilo terminal
    text-align: center;
    animation: glitch 1.5s infinite; // Efeito de glitch
  
    .hacker-title {
      font-size: 3rem;
      margin-bottom: 1.5rem;
      text-shadow: 0 0 10px #ff0000, 0 0 20px #ff0000, 0 0 30px #ff0000; // Efeito neon
    }
  
    .user-ip {
      font-size: 1.5rem;
      margin-bottom: 2rem;
      
      span {
        color: #ff0000; // Destaque para o IP do usuário
      }
    }
  
    .screenshot-container {
      background-color: #111;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0px 0px 10px 5px rgba(255, 0, 0, 0.7);
  
      h3 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
      }
  
      img {
        width: 300px;
        height: auto;
        border: 2px solid #ff0000;
      }
    }
  }
  
  // Efeito de glitch
  @keyframes glitch {
    0% {
      transform: translate(0);
    }
    20% {
      transform: translate(-2px, 2px);
    }
    40% {
      transform: translate(-1px, -1px);
    }
    60% {
      transform: translate(2px, -2px);
    }
    80% {
      transform: translate(1px, 1px);
    }
    100% {
      transform: translate(0);
    }
  }
  
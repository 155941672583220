// _variables.scss
$card-bg: #17161A;
$radius: 6px;
$primary-color: #ffaa00;
$secondary-color: #10b981;
$text-color: #fff;
$bg-overlay: rgba(0, 0, 0, 0.822);
$shadow-color: rgba(0, 0, 0, 0.3);
$balance-bg: #252429;

.background-container {
  position: relative;
  background: url('../../assets/login/bg.png') center/cover no-repeat;
  color: $text-color;
  width: 100%;
  height: 130vh;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: $bg-overlay;
    z-index: 0;
  }

  .container {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
  }

  .row-infos {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    border-radius: $radius;

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
    }

    .affiliates-card,
    .balance-card {
      background-color: $card-bg;
      border-radius: $radius;
      padding: 20px;
      box-shadow: 0 0 10px $shadow-color;
      flex-basis: 100%;

      .card-titulo {
        margin-bottom: 12px;
      }

      @media (min-width: 768px) {
        flex-basis: 48%;
      }

      .nivel-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $text-color;
        flex-direction: column;

        p {
          font-size: 25px;
          font-weight: bold;
        }

        .icon {
          color: $secondary-color;
          font-size: 24px;
        }
      }
    }

    .affiliates-card {
      .nivel-item {
        p {
          font-size: 2rem;
        }

        svg {
          width: 120px;
          color: #fff;
          fill: $primary-color;
        }
      }
    }
  }

  .balance-card .nivel-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    padding: 20px;
    background-color: $balance-bg;
    background-image: url('../../assets/money-bag.png');
    background-position: right;
    background-repeat: no-repeat;
    border-radius: $radius;

    p {
      font-family: sans-serif;
      color: #04C600;
      font-size: 25px;
      font-weight: 500;
    }
  }

  .row-chart {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    border-radius: $radius;

    @media (min-width: 768px) {
      flex-direction: row;
    }

    .vendas-mes {
      background-color: $card-bg;
      border-radius: $radius;
      box-shadow: 0 0 10px $shadow-color;
      width: 100%;
      padding: 20px;

      .grafico-titulo {
        color: $text-color;
        font-size: 18px;
        margin-bottom: 10px;
      }
    }
  }
}

.steps-afiliate {
  display: flex;
  justify-content: center;
  padding: 10px;
  background-color: #0e0e10;
  width: 100%;

  ul {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    max-width: 1200px;
  }

  li {
    text-align: center;
    padding: 4px;
    position: relative;
    color: $text-color;
    transition: all 0.3s ease;
    border: 4px solid transparent;
    font-size: 0.7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    clip-path: polygon(10% 0%, 100% 0, 90% 100%, 0% 100%);
    cursor: pointer;

    .step-number {
      height: 40px;
      width: 100px;
      max-width: 120px;
      margin-bottom: 20px;
      border-bottom: 2px solid $primary-color;
      border-radius: $radius;
      padding-bottom: 10px;
      text-shadow: -1px 2px 4px rgba(255, 166, 0, 0.253);
      font-weight: bold;
      font-size: 1.3rem;
    }

    .step-content h3 {
      font-weight: bold;
    }

    &.active {
      border-color: $primary-color;
      animation: border-grow 2s linear infinite;
    }
  }

  @keyframes border-grow {
    50% {
      border-color: $primary-color;
    }
  }
}

.affiliate-link-card {
  background-color: $card-bg;
  padding: 20px;
  border-radius: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: start;
  flex-direction: column;
}

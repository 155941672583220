$card-bg: #17161A;
$radius: 6px;
$primary-color: #ffaa00;
$secondary-color: #10b981;
$text-color: #fff;
$bg-overlay: rgba(0, 0, 0, 0.822);
$shadow-color: rgba(0, 0, 0, 0.3);
$balance-bg: #252429;

.ranking-container {
  position: relative;
  background: url('../../assets/login/bg.png') center/cover no-repeat;
  color: $text-color;
  width: 100%;
  height: 100%;
  padding-bottom: 100px;
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: $bg-overlay;
    z-index: 0;
  }

  .container {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px 0;

    h2 {
      text-align: center;
      font-size: 2rem;
      font-weight: bold;
      text-shadow: 0px 0px 14px #000;
      letter-spacing: 5px;
    }
  }

  .ranking-card {
    position: relative;
    background-color: $card-bg;
    border-radius: 20px 20px 0 0;
    padding: 60px 30px 300px;
    box-shadow: 0 0 10px $shadow-color;
    height: 100vh;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #f8fde5;
    border: solid 2px #f8fde5;
    margin-bottom: -20px;
    border-top: 6px solid #f8fde5;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: $card-bg;
      border-radius: 50%;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #f8fde5;
      border-radius: 50%;
      border: 3px solid $card-bg;
      box-shadow: inset 1px 1px 5px rgba(255, 255, 255, 0.6), 1px 1px 8px rgba(0, 0, 0, 0.5);
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #f8fde5;
      box-shadow: inset 1px 1px 5px rgba(255, 255, 255, 0.8), 1px 1px 10px rgba(0, 0, 0, 0.7);
    }
  }

  .ranking-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0;
    list-style: none;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      background-color: $balance-bg;
      border-radius: $radius;
      box-shadow: 0 0 5px $shadow-color;

      .user-info {
        display: flex;
        align-items: center;
        gap: 20px;

        .user-photo {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          background-color: $secondary-color;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;

          &.top-3 {
            border: 2px solid $primary-color;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .user-name {
          font-size: 0.8rem;
          font-weight: bold;
          color: $text-color;
        }
      }

      .user-score {
        text-align: right;

        p {
          font-size: 12px;
        }

        b {
          font-size: 22px;
          color: #04C600;
          text-align: right;
          width: 100%;
        }
      }
    }
  }

  .row-img {
    display: flex;
    justify-content: center;
  }

  .trofeu {
    width: 250px;
    margin-bottom: -8px;
    z-index: 99;
  }
}

.timeline {
  list-style: none;
  padding: 0;
  margin-top: 20px;

  li {
    margin-bottom: 10px;
    font-size: 1rem;
    color: $text-color;

    &:before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      background-color: $primary-color;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
}

.modal-content {
  color: $text-color; // Todos os textos do modal serão brancos
  background-color: $card-bg; // Fundo do modal será igual ao card do ranking

  h2, p, li {
    color: $text-color; // Certifica-se de que todos os textos, incluindo título, parágrafos e itens de lista, sejam brancos
  }
}
                                                                       
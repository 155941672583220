$card-bg: #17161A;
$primary-color: #ffaa00;
$text-color: #fff;
$shadow-color: rgba(0, 0, 0, 0.3);
$progress-color: #00bfff;
$total-earned-color: #04c600;

.background-container {
  background: url('../../assets/login/bg.png') center/cover no-repeat;
  color: $text-color;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  justify-content: center;
}

.result-card {
  background-color: $card-bg;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 0 15px $shadow-color;
  text-align: center;
  width: 100%;
}

.result-title {
  color: $text-color;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.progress-container {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  width: 100%;

  .trophy-icon {
    transform: translate(40px, -4px);
  }
}

.trophy-icon {
  font-size: 40px;
  color: $primary-color;
}

.stats-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-width: 400px;
  margin-bottom: 26px;

  h3 {
    font-weight: bold;
    font-size: 1.3rem;
  }
}

.stats-item {
  color: $text-color;
}


.total-container {
  background: #252429;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 30px;
  width: 100%;
  max-width: 400px;

  p {
    font-size: bold;
  }
}

.total-earned {
  color: $total-earned-color;
  font-size: 2.5rem;
  font-weight: 500;
}

.menu-button {
  width: 100%;
  color: $text-color;
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .result-card {
    padding: 20px;
    max-width: 90%;
  }

  .trophy-icon {
    font-size: 50px;
  }

  .total-earned {
    font-size: 2rem;
  }
}
.background-container {
  position: relative;
  background: url('../../assets/login/bg.png') center/cover no-repeat;
  color: #fff;
  width: 100%;
  height: 100%;
  padding-bottom: 100px;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.7); // Transparência no background
    z-index: 0;
  }
}

.withdraw-container {
  padding: 20px;
  z-index: 10;
  position: relative;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 20px;
}

.balance-card {
  grid-column: span 12 / span 12;

  @media (min-width: 768px) {
    grid-column: span 6 / span 6;
  }
}

.input-card {
  grid-column: span 12 / span 12;

  @media (min-width: 768px) {
    grid-column: span 6 / span 6;
  }

  border-radius: 6px;
  padding: 20px;
  background-color: #17161a;
  width: 100%;
  height: 100%;

  .styled-input,
  .styled-select {
    width: 100%;
    padding: 10px;
    margin-bottom: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #fff;
    color: #000;
    font-size: 16px;
  }

  button {
    margin-top: 8px;
    width: 100%;
    background-color: #10b981;
    padding: 10px;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: #0a8f64;
    }
  }
}

.table-container {
  margin-top: 20px;

  table {
    width: 100%;
    border-collapse: collapse;
    background-color: #17161a; // Mesma cor do balance card
    color: #fff; // Texto branco
    border-radius: 8px;
    overflow: hidden;
    th,
    td {
      padding: 10px;
      border: 1px solid #2d2d2d; // Bordas escuras
      text-align: left;
    }

    th {
      background-color: #1f1f1f; // Cabeçalho mais escuro
      color: #ffffff;
    }

    tr:nth-child(even) {
      background-color: #1a1a1a; // Linhas pares
    }

    tr:nth-child(odd) {
      background-color: #202020; // Linhas ímpares
    }

    tr:hover {
      background-color: #2b2b2b; // Hover sobre a linha
    }

    td {
      font-size: 14px;
    }
  }
}

.pagination-container {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;

  button {
    padding: 10px 20px;
    background-color: #10b981; // Botão de paginação verde
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;

    &:hover {
      background-color: #0a8f64;
    }

    &:disabled {
      background-color: #3c3c3c;
      cursor: not-allowed;
    }
  }
}

.loader {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #ffffff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 0.8s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

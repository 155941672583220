@import '../../styles/_variables';

.register-page {
  background-image: url('/assets/login/bg.png');
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: #fff;
    cursor: pointer;
  }
}

.register-container {
  border-radius: 8px;
  width: 100%;
  max-width: 400px;

}

.register-input {
  border-color: $primary-color;
}

.register-button {
  width: 100%;
}

.register-footer {
  color: #fff;
}

.register-footer .logo {
  width: 150px;
}

.form-container {
  width: 100%;
}

.texto-voltar {
  color: #fff;
  cursor: pointer;
  transition: ease 300ms;
}

.texto-voltar:hover {
 transform: scale(1.05);
}